import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './index.css';
import HomePage from './components/SitePages/HomePage.js';
import Performances from './components/SitePages/Performances.js';
import Contact from './components/SitePages/Contact.js';
import OurTeam from './components/SitePages/OurTeam.js';
import Navbar from './components/Navbar.js';

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" exact element={<HomePage />} />
          <Route path="/performances" element={<Performances />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/our-team" element={<OurTeam />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
