import React from 'react'
import'../../App.css'
import Main from '../Main.js'
import CardsComp from '../CardsComp.js';
import Footer from '../Footer.js';
import About from '../About.js';
import FRQ from '../FRQ.js';

function HomePage () {
    return (
    <>
      <Main />
      <About/>
      <CardsComp/>
      <FRQ/>
      <Footer/>

    </>
    ); 
}

export default HomePage;