import React from 'react';
import '../../App.css';
import Footer from '../Footer.js';
import '../Footer.css';
import { Button } from '../Button.js';
import '../../components/Performances.css';

export default function Performances() {
    const renaissance = `UGA Asura's latest theme brings the Renaissance to life, capturing its spirit of innovation and artistry. 
    From the harmonious revival of classical music to the dramatic words of Shakespearean comedy and tragedy, 
    UGA Asura offers a step into the most extraordinary time of creativity and change.`;

    const pied_piper = `For their 15th season, Asura presents a chilling retelling of the Pied Piper legend.
    When a feral pack of rabid dogs ravage the village of Athens, the Pied Piper arrives 
    with a straightforward deal: she will rid the village of the dogs in exchange 
    for a home. When her request is denied by the queen, the Pied Piper exacts revenge by leading the 
    village's beloved children away to an unknown fate.`;

    const game_night = `Game Night was all about fun, strategy, and surprises. UGA Asura delivered an electrifying performance, bringing your favorite games to life on stage. Each dance sequence was designed to capture the excitement and thrill of a competitive game night.`;

    return (
        <div className='main_perf'>
            <div className='performances_container text-white'>
                <h1 className='text-3xl font-bold mt-10 text-center'>Performances</h1>
                <hr className="solid_line mt-5 opacity-20" />

                <div className="performance_section flex flex-col md:flex-row items-center md:items-start">
                    <div className='performance_img_container mt-5'>
                        <img className="performance_img" src='/17.0_performances.jpeg' alt='17.0_img' />
                    </div>
                    <div className='text_container text-center md:text-left'>
                        <h2 className='text-2xl font-bold mt-5'>The Renaissance (2023-2024)</h2>
                        <div className='performance_info font-bold opacity-80 mt-5'>
                            <p className='text-center md:text-left'>Year: 16.0</p>
                            <p className='text-center md:text-left'>Captains: Disha Adama, Tanvi Gujral, Roshan Maganti, Avni Mahatme, Meiga Thomas</p>
                        </div>
                        <div className='performance_description font-bold'>
                            <p className='text-center md:text-left mb-5 opacity-80'>{renaissance}</p>
                            <a href="https://www.youtube.com/watch?v=TKVA2LkEdss" target="_blank" rel="noopener noreferrer">
                                <Button className="video_btn mb-5" buttonStyle='btn_outline' buttonSize='btn_large'>Watch</Button>
                            </a>
                        </div>
                    </div>
                </div>

                <hr className='solid_line mb-5 opacity-20' />

                <div className="performance_section flex flex-col md:flex-row items-center md:items-start">
                    <div className='performance_img_container mt-5'>
                        <img className="performance_img" src='/Pied_Piper_img.jpg' alt='15.0_img' />
                    </div>
                    <div className='text_container text-center md:text-left'>
                        <h2 className='text-2xl font-bold mt-5'>The Pied Piper (2022-2023)</h2>
                        <div className='performance_info opacity-80'>
                            <p className='text-center md:text-left font-bold'>Year: 15.0</p>
                            <p className='text-center md:text-left font-bold'>Captains: Disha Adama, Ananya Buxi, Tanvi Gujral, Cyril Joseph</p>
                        </div>
                        <div className='performance_description mt-5'>
                            <p className='text-center md:text-left font-bold opacity-80'>{pied_piper}</p>
                            <a href="https://www.youtube.com/watch?v=ahsgxgPyXAM&t=4s" target="_blank" rel="noopener noreferrer">
                                <Button className="video_btn mb-5 mt-5 font-bold" buttonStyle='btn_outline' buttonSize='btn_large'>Watch</Button>
                            </a>
                        </div>
                    </div>
                </div>

                <hr className='solid_line opacity-20' />

                <div className="performance_section flex flex-col md:flex-row items-center md:items-start">
                    <div className='performance_img_container mt-5'>
                        <img className="w-[200px] h-[200px] performance_img" src='/Game_Night.jpg' alt='Game_Night_img' />
                    </div>
                    <div className='text_container text-center md:text-left'>
                        <h2 className='text-2xl font-bold mt-5'>Game Night (2021-2022)</h2>
                        <div className='performance_info opacity-80 mt-5'>
                            <p className='text-center md:text-left font-bold'>Year: 14.0</p>
                            <p className='text-center md:text-left font-bold'>Captains: Disha Adama, Ananya Buxi, Tanvi Gujral, Cyril Joseph</p>
                        </div>
                        <div className='performance_description'>
                            <p className='text-center md:text-left font-bold opacity-80'>{game_night}</p>
                            <a href="https://www.youtube.com/watch?v=VtJnDIMkPP8" target="_blank" rel="noopener noreferrer">
                                <Button className="video_btn mb-5 mt-5 font-bold" buttonStyle='btn_outline' buttonSize='btn_large'>Watch</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}