import React, { useState } from 'react';
import './FRQ.css';

function FRQ() {
  const [clicked, setClick] = useState(null);

  const toggleQuestion = (index) => {
    setClick((prevState) => (prevState === index ? null : index));
  };

  return (
    <section className="frq-section bg-black p-10">
      <h1 className="font-bold text-center text-4xl mb-10 text-white">Frequently Asked Questions</h1>
      <div className="faq-container max-w-4xl mx-auto">
        {questions.map((item, index) => (
          <div
            className={`faq-item border-b border-white ${
              clicked === index ? 'active' : ''
            }`}
            key={index}
          >
            <button
              onClick={() => toggleQuestion(index)}
              className="faq-question w-full flex justify-between mt-4 items-center font-semibold text-lg text-white focus:outline-none"
              aria-expanded={clicked === index}
            >
              <span>{item.question}</span>
              <span className="text-2xl text-white">{clicked === index ? '-' : '+'}</span>
            </button>
            <div
              className={`faq-answer overflow-hidden transition-all duration-300 mb-2 ${
                clicked === index ? 'max-h-40 opacity-100' : 'max-h-0 opacity-0'
              }`}
              style={{
                transitionProperty: 'max-height, opacity-20',
                maxHeight: clicked === index ? '200px' : '0',
              }}
            >
              <p className="text-white text-base">{item.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

const questions = [
  {
    question: 'Why is the moon sometimes out during the day?',
    answer: 'The moon is visible during the day because it reflects sunlight and its orbit places it in the sky during daytime hours.',
  },
  {
    question: 'Why is the sky blue?',
    answer: 'The sky appears blue due to the scattering of sunlight by the atmosphere, which is more effective at shorter (blue) wavelengths.',
  },
  {
    question: 'Will we ever discover aliens?',
    answer: 'Scientists continue to search for extraterrestrial life through various means, but no evidence has been found yet.',
  },
  {
    question: 'How much does Earth weigh?',
    answer: 'Earth weighs approximately 5.972 × 10^24 kilograms.',
  },
  {
    question: 'How do airplanes stay up?',
    answer: 'Airplanes stay up due to the lift generated by their wings, which counteracts gravity.',
  },
];

export default FRQ;
