import React from 'react';

function Footer() {
  return (
    <footer className="bg-black text-white text-roboto">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-10 md:space-y-0 md:space-x-20">
          {/* Logo */}
          <div className="text-center md:text-left">
            <img
              src="/asura_logo.jpeg"
              alt="Asura Logo"
              className="w-30 h-30 mx-auto md:mx-0"
            />
          </div>

          {/* Quick Links */}
          <div className="text-center md:text-left">
            <h3 className="text-base font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/"
                  className="text-base text-gray-400 hover:text-white"
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/performances"
                  className="text-base text-gray-400 hover:text-white"
                >
                  Performances
                </a>
              </li>
              <li>
                <a
                  href="/our_team"
                  className="text-base text-gray-400 hover:text-white"
                >
                  Our Team
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className="text-base text-gray-400 hover:text-white"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Information */}
          <div className="text-center md:text-left">
            <h3 className="text-base font-semibold mb-4 mt-2">Contact Information</h3>
            <ul className="space-y-2">
              <li className="text-base text-gray-400 opacity-100">
                <strong>Email:</strong>{' '}<br/>
                <a
                  className="hover:underline text-white"
                  href="mailto:ugaasura@gmail.com"
                >
                  ugaasura@gmail.com
                </a>
              </li>
              <li className="text-base text-gray-400">
                <strong>Address:</strong>
                <span className="block">
                  University of Georgia Chapel
                </span>
                <span className="block">Herty Dr, Athens, GA 30602</span>
              </li>
            </ul>
          </div>
        </div>

        {/* Social Media Icons */}
        <div className="flex justify-center space-x-8 mt-8">
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/instaLogo.png"
              alt="Instagram"
              className="w-12 h-12 rounded-full"
            />
          </a>
          <a
            href="https://www.youtube.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/youtubeLogo.jpeg"
              alt="YouTube"
              className="w-12 h-12 rounded-full"
            />
          </a>
          <a
            href="https://www.tiktok.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/tiktokLogo.jpeg"
              alt="TikTok"
              className="w-12 h-12 rounded-full"
            />
          </a>
        </div>

        {/* Footer Text */}
        <div className="mt-8 text-center text-gray-400 text-xs" >
          <p className="">&copy; 2025 UGA Asura. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
