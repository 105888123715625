import React from 'react';
import './About.css'

function Sponsor_intro() {
  const support_description = `Welcome to UGA Asura, the University of Georgia's premier co-ed Bollywood fusion dance team! Founded in 2007, we combine different styles of dance, including Western hip-hop, Bharatanatyam, Kathak, Bhangra, Contemporary, Bollywood, and Kuthu, into electrifying performances that celebrate cultural fusion. Coming into our 17th season, we cannot wait to express our creativity and dedication on stage. Thanks for your support, and we can't wait to give you an unforgettable show this year!`;

  return (
    <div className="sponsor flex flex-col md:flex-row items-center p-5 md:p-10">
      <img src="/17.0_img.png" alt="Sponsor" className="w-full md:w-1/3 h-auto rounded-lg mb-5 md:mb-0 md:mr-10" />
      <div className="sponsor_information flex-1 text-center md:text-left">
        <h1 className="text-4xl md:text-5xl font-bold text-white mb-5">Our History</h1>
        <p className="text-left text-white text-opacity-80 leading-relaxed">{support_description}</p>
      </div>
    </div>
  );
}

export default Sponsor_intro;