import React from 'react';
import { Link } from 'react-scroll'; // Import Link for smooth scrolling
import './Main.css'

function Main() {
  return (
    <div className='container'>
      <video src="/Asura_Intro.mp4" autoPlay muted loop />
      <h1 className='font-bold ml-[200px]'>Welcome to UGA Asura</h1>
      <p className='font-bold ml-[200px]'>The University of Georgia's premier Co-ed Bollywood Fusion Dance Team</p>
      <div className='main_btn flex space-x-4 ml-[150px]'>
        {/* "Get Started" button scrolls to CardsComp section */}
        <Link to="cardsCompSection" spy={true} smooth={true} offset={20} duration={500}>
          <button className='btn font-bold btn_outline btn_large w-60'>
            Get Started
          </button>
        </Link>
        {/* "Donate" button links to the GoFundMe page */}
        <a
          href="https://www.gofundme.com/f/support-uga-asuras-17th-dance-season?qid=e7337743f454c4e3dc9e29104c8e9790"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className='btn font-bold btn_outline btn_large w-60 hover:bg-red-500 '>
            Donate
          </button>
        </a>
      </div>
    </div>
  );
}

export default Main;
