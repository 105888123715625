import React from 'react';
import { Link } from 'react-router-dom';

function CardsComp() {
  return (
    <div id="cardsCompSection" className="relative flex min-h-screen items-center justify-center bg-black mt-5">
      <div className="absolute top-[50px] bottom-[150px] grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 pt-10 w-full">

        {/* Card 1 */}
        <a href="#" className="group relative block bg-black h-[350px] sm:h-[400px] lg:h-[550px]">
          <img
            alt=""
            src="/Performance2_IMG.png"
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />
          <div className="relative p-4 sm:p-6 lg:p-8 flex flex-col justify-between h-full">
            <p className="text-sm font-medium uppercase tracking-widest text-pink-500">See Us Live</p>
            <p className="text-xl font-bold text-white sm:text-2xl mt-4">Upcoming Shows</p>
            <div className="mt-4 flex-grow">
              <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                <p className="text-lg text-white">
                  <strong>Aag Ki Raat (Columbia, SC):</strong> <br/>February 2nd, 2025 <br />
                  <strong>UGA India Night (Athens, GA):</strong> <br/>February 8th, 2025 <br />
                  <strong>NJ Naach (Trenton, NJ):</strong> <br/>February 22nd, 2025 <br />
                  <strong>Magic City Maza (Birmingham, AL):</strong> <br/>March 1st, 2025 <br />
                  <strong>Tufaan (Evanston, IL):</strong><br/> March 22nd, 2025
                </p>
              </div>
            </div>
            <div className="absolute bottom-8 left-0 right-0 px-4 sm:px-6 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <a
                href="https://www.gofundme.com/f/support-uga-asuras-17th-dance-season"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-[400px] rounded-full bg-red-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 hover:bg-red-700">
                  Support
                </button>
              </a>
            </div>
          </div>
        </a>

        {/* Card 2 */}
        <a href="#" className="group relative block bg-black h-[350px] sm:h-[300px] lg:h-[550px]">
          <img
            alt=""
            src="/Performance_IMG.png"
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />
          <div className="relative p-4 sm:p-6 lg:p-8 flex flex-col justify-between h-full">
            <p className="text-sm font-medium uppercase tracking-widest text-pink-500">Our Performances</p>
            <p className="text-xl font-bold text-white sm:text-2xl mt-4">Watch Our Journey</p>
            <div className="mt-4 flex-grow">
              <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                <p className="text-lg text-white">
                  Check out our performances over the years, showing our journey and passion for creating unforgettable dance experiences.
                </p>
              </div>
            </div>
            <div className="absolute bottom-8 left-0 right-0 px-4 sm:px-6 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <Link to="/performances">
                <button className="w-[400px] rounded-full bg-red-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 hover:bg-red-700">
                  Watch
                </button>
              </Link>
            </div>
          </div>
        </a>

        {/* Card 3 */}
        <a href="#" className="group relative block bg-black h-[350px] sm:h-[300px] lg:h-[550px]">
          <img
            alt=""
            src="/Performance3_IMG.jpeg"
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />
          <div className="relative p-4 sm:p-6 lg:p-8 flex flex-col justify-between h-full">
            <p className="text-sm font-medium uppercase tracking-widest text-pink-500">Contact Us</p>
            <p className="text-xl font-bold text-white sm:text-2xl mt-4">Get In Touch</p>
            <div className="mt-4 flex-grow">
              <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                <p className="text-lg text-white">
                  Looking for dancers for your next performance? Want to become an official sponsor or have general questions? Reach out to us!
                </p>
              </div>
            </div>
            <div className="absolute bottom-8 left-0 right-0 px-4 sm:px-6 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <Link to="/contact">
                <button className="w-[400px] rounded-full bg-red-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 hover:bg-red-700">
                  Contact
                </button>
              </Link>
            </div>
          </div>
        </a>

      </div>
    </div>
  );
}

export default CardsComp;
