import React from 'react';
import Footer from '../Footer.js';
import '../Footer.css';

function Our_team() {
  return (
    <div className="our-team-container px-5 py-10 flex flex-col gap-8 justify-center items-center">
      {/* Captains Section */}
      <div className="captain-title text-center font-roboto">
        <h1 className="text-4xl font-bold text-white">Captains</h1>
      </div>
      <div className="members-row flex flex-wrap justify-center gap-5">
        {[
          { name: 'Valencia John', role: 'captain', img: '/Val_img.jpg' },
          { name: 'Irene Johnson', role: 'captain', img: '/Irene_img.jpg' },
          { name: 'Neha Venkatesh', role: 'captain', img: '/Neha_img.jpg' }
        ].map((member, index) => (
          <div className="stats p-4 w-72 h-32 bg-black rounded-lg shadow-lg flex flex-row items-center justify-between border-2 border-red-900" key={index}>
            <div className="avatar rounded-full overflow-hidden w-20 h-20">
              <img src={member.img} alt={member.name} className="object-cover w-full h-full" />
            </div>
            <div className="text-white ml-4">
              <div className="stat-title text-xl font-semibold mb-1">{member.name}</div>
              <div className="text-sm">
                {member.role === 'captain' ? 'Captain' : member.role === 'exec board' ? 'Exec Board' : 'Dancer'}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Executive Board Section */}
      <div className="exec-board text-center font-roboto">
        <h1 className="text-4xl font-bold text-white mb-5">Executive Board</h1>
        <div className="members-row flex flex-wrap justify-center gap-5">
          {[
            { name: 'Raksha Adama', role: 'Production Chair', img: '/Raksha_img.jpg' },
            { name: 'Aditi Chiluguri', role: 'Competition Chair', img: 'Aditi_Img.jpg' },
            { name: 'Shania D\'Costa', role: 'Social Media Chair', img: '/Shania_img.jpg'},
            { name: 'Medha Paramesh', role: 'Finance Chair', img: '/Medha_img.jpg'}
          ].map((member, index) => (
            <div className="stats p-4 w-[300px] h-32 bg-black rounded-lg shadow-lg flex flex-row items-center justify-between border-2 border-red-900" key={index}>
              <div className="avatar rounded-full overflow-hidden w-20 h-20">
                <img src={member.img} alt={member.name} className="object-cover w-full h-full" />
              </div>
              <div className="text-white ml-4">
                <div className="stat-title text-xl font-semibold mb-1">{member.name}</div>
                <div className="text-sm text-right">{member.role}</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Dancers Section */}
      <div className="dancer">
        <h1 className="text-4xl font-bold text-white mb-5 text-center">Dancers</h1>
        <div className="dancer-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
          {[
            { name: 'Aashna Lal', img: 'Aashna_img.jpg' },
            { name: 'Arya Anil', img: '/Arya_img.jpg' },
            { name: 'Ashwin Sanjay', img: '/Ashwin_img.jpg' },
            { name: 'Deeya Patel', img: '/Deeya_img.jpg' },
            { name: 'Diya Panchavadi', img: '/Diya_img.jpg' },
            { name: 'Irene Jacob', img: '/Jacob_img.jpg' },
            { name: 'Jiya Patel', img: '/Jiya_img.jpg' },
            { name: 'Jun Chen', img: '/Jun_img.png' },
            { name: 'Manaswi Gorle', img: '/Manaswi_img.jpg' },
            { name: 'Nayan Annadurai', img: '/Nayan_img.jpg' },
            { name: 'Nupur Desai', img: '/Nupur_img.jpg' },
            { name: 'Nymisha Vitta', img: '/Nymisha_img.jpg' },
            { name: 'Olivia Kachappilly', img: '/Olivia_img.jpg' },
            { name: 'Reena Thangamani', img: '/Reena_img.jpg' },
            { name: 'Ritika Vakharia', img: '/Ritika_img.jpg' },
            { name: 'Samrath Singh', img: '/Samrath_img.jpg' },
            { name: 'Shifa Hirani', img: '/Shifa_img.jpg' },
            { name: 'Shivani Menon', img: '/Shivani_img.jpg' },
            { name: 'Sami Meka', img: '/Sami_img.jpg' },
            { name: 'Tanvi Mekala', img: '/Meeks_img.jpg' },
            { name: 'Vedanth Natarajan', img: '/Vedanth_img.jpg' },
            { name: 'Yash Pandibabu', img: '/Yash_img.jpg' }
          ].map((dancer, index) => (
            <div className="stats p-4 w-90 h-[80px] bg-black rounded-lg shadow-lg flex flex-row items-center justify-between border-2 border-red-900" key={index}>
              <div className="avatar rounded-full overflow-hidden w-[70px] h-[70px]">
                <img src={dancer.img} alt={dancer.name} className="object-cover w-full h-full" />
              </div>
              <div className="text-white ml-4">
                <div className="stat-title text-xl font-semibold mb-1">{dancer.name}</div>
                <div className="text-sm text-right">Dancer</div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Our_team;
