import React, { useState } from 'react';
import Footer from '../Footer.js';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message) {
      setError('All fields are required!');
      return;
    }

    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch('http://localhost:5001/send-message', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({ name: '', email: '', message: '' });
        setSuccess(true);
      } else {
        const result = await response.json();
        setError(result.error || 'An error occurred, please try again later.');
      }
    } catch (error) {
      setError('Network error, please check your connection.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
          {/* Contact Form */}
          <div className="bg-black-900 rounded-lg shadow-lg p-5 border border-zinc-800 w-full h-[620px]">
            <h2 className="text-2xl font-semibold mb-6 ml-4">Send Us A Message</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <input
                  className="w-full px-4 py-2 rounded-lg bg-black border border-zinc-700 text-white placeholder-zinc-400 focus:ring-2 focus:ring-red-500 focus:border-transparent"
                  type="text"
                  id="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <input
                  className="w-full px-4 py-2 rounded-lg bg-black border border-zinc-700 text-white placeholder-zinc-400 focus:ring-2 focus:ring-red-500 focus:border-transparent"
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <textarea
                  className="w-full px-4 py-2 rounded-lg bg-black border border-zinc-700 text-white placeholder-zinc-400 focus:ring-2 focus:ring-red-500 focus:border-transparent resize-none"
                  id="message"
                  rows="10"
                  placeholder="Type your message here..."
                  value={formData.message}
                  onChange={handleChange}
                />
              </div>
              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full bg-red-900 text-white py-3 px-4 rounded-lg hover:bg-red-700 transition-colors disabled:bg-zinc-600"
              >
                {isSubmitting ? 'Sending...' : 'Submit'}
              </button>
            </form>
            {error && (
              <div className="mt-4 p-3 bg-red-900/50 text-red-200 rounded-lg border border-red-700">
                {error}
              </div>
            )}
            {success && (
              <div className="mt-4 p-3 bg-green-900/50 text-green-200 rounded-lg border border-green-700">
                Your message has been sent!
              </div>
            )}
          </div>

          {/* Replaced Cards */}
          <div className="space-y-8">
            {/* Card 1 */}
            <a
              href="#"
              className="group relative block bg-black h-[350px] sm:h-[300px] lg:h-[300px] w-[650px] "
            >
              <img
                alt=""
                src="/contactImg1.gif"
                className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />
              <div className="relative p-4 sm:p-6 lg:p-8 flex flex-col justify-between h-full">
                <p className="text-sm font-medium uppercase tracking-widest text-pink-500">
                  See Us Live
                </p>
                <p className="text-xl font-bold text-white sm:text-2xl mt-4">
                  Upcoming Shows
                </p>
                <div className="mt-4 flex-grow">
                  <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                  <a
                href="https://www.gofundme.com/f/support-uga-asuras-17th-dance-season"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="w-[400px] rounded-full bg-red-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 hover:bg-red-700 ml-[100px] mt-[100px]">
                  Support
                </button>
              </a>
                  </div>
                </div>
              </div>
            </a>

            {/* Card 2 */}
            <a
              href="#"
              className="group relative block bg-black h-[350px] sm:h-[300px] lg:h-[300px] w-[650px]"
            >
              <img
                alt=""
                src="/contactImg2.gif"
                className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
              />
              <div className="relative p-4 sm:p-6 lg:p-8 flex flex-col justify-between h-full">
                <p className="text-4xl font-bold text-white sm:text-2xl ">
                  Watch Our Journey
                </p>
                <div className="mt-4 flex-grow">
                  <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                  <a
                href="https://www.gofundme.com/f/support-uga-asuras-17th-dance-season"
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>Hello </p>
                <button className="w-[400px] rounded-full bg-red-900 py-2 px-3.5 font-com text-sm capitalize text-white shadow shadow-black/60 hover:bg-red-700 ml-[100px] mt-[100px]">
                  Support
                </button>
              </a>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
